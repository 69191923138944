<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Page d'arrivée</v-card-title>
        <v-card-subtitle>Choisissez sur quelle page vous souhaitez être redirigé lors de la connexion</v-card-subtitle>
        <v-card-text>
            <v-select v-model="start_page.selected_page" :items="start_page.available_page" item-text="name" item-value="id"></v-select>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" :disabled="isChanges()" @click="resetSettings">Annuler</v-btn>
            <v-btn color="success" :disabled="isChanges()" @click="saveSettings">Enregistrer</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'StartPage',
    data() {
        return {
            start_page : JSON.parse(localStorage.getItem('settings')).preferencies.start_page,
            start_page_backup : JSON.parse(localStorage.getItem('settings')).preferencies.start_page,
        }
    },
    methods : {
        saveSettings() {
            console.log(this.start_page)
            if (this.start_page_backup !== this.start_page) {
                const settings = JSON.parse(localStorage.getItem('settings'));
                settings.preferencies.start_page = this.start_page;
                this.start_page_backup = JSON.parse(JSON.stringify(this.start_page));
                localStorage.setItem('settings', JSON.stringify(settings));
            }
        },
        resetSettings() {
            this.start_page = JSON.parse(JSON.stringify(this.start_page_backup));
        },
        isChanges () {
            if (JSON.stringify(this.start_page_backup) !== JSON.stringify(this.start_page)) {
                return false;
            } else {
                return true;
            }
        }
    },
}
</script>